.header_logo svg {
  width: 160px;
}
.left_content_footer svg {
  width: 160px;
}
@media screen and (min-width: 1430px) and (max-width: 1620px) {
  .manage_card {
    min-height: 330px !important;
    width: auto !important;
  }
  .truck_pic {
    width: 300px !important;
    height: 107px !important;
  }
  .fixing-page .container,
  .fixing-page .container-lg,
  .fixing-page .container-md,
  .fixing-page .container-sm,
  .fixing-page .container-xl,
  .fixing-page .container-xxl {
    max-width: 1345px !important;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1430px) {
  .manage_card {
    min-height: 290px !important;
    width: 230px !important;
  }
  .truck_pic {
    width: 240px !important;
    height: 86px !important;
  }
  .fully_manage_section .bottom_title p {
    font-size: 15px !important ;
  }
  .fixing-page .container,
  .fixing-page .container-lg,
  .fixing-page .container-md,
  .fixing-page .container-sm,
  .fixing-page .container-xl,
  .fixing-page .container-xxl {
    max-width: 1170px !important;
  }
  .fixing-page .left_content_data h3 {
    font-size: 35px !important;
    line-height: 44.8px;
  }
  .fixing-page .team_content {
    font-size: 12px !important;
  }
  .fixing-page .fully_manage_section .bottom_title p {
    font-size: 15px !important ;
  }
  .fixing-page .header_logo svg {
    width: 129px !important;
  }
  .fixing-page .header-menu {
    font-size: 13px !important;
  }
  .fixing-page button {
    font-size: 13px !important;
    padding: 7px 20px !important;
  }
  .fixing-page .load_page button{
    padding: 7px 0px !important;
  }
  .fixing-page .left_content_data span {
    font-size: 19px !important;
    line-height: 24px !important;
  }
  .fixing-page .left_content_data h6 {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .fixing-page h3 {
    font-size: 30px !important;
  }
  .fixing-page .calculator_div h3 {
    line-height: 36px !important;
  }
  .fixing-page .calculator_div p {
    font-size: 18px !important;
  }
  .fixing-page .team_card h6 {
    font-size: 18px !important;
  }
  .fixing-page .team_card p {
    font-size: 13px !important;
  }
  .fixing-page .service_card h6 {
    font-size: 20px !important;
  }
  .fixing-page .service_card p {
    font-size: 13px !important;
    line-height: 18px !important;
    margin-top: 5px;
  }
  .fixing-page .fully_manage_section p {
    line-height: 20px !important;
    font-size: 14px !important;
  }
  .fixing-page .service_name {
    font-size: 15px !important;
  }
  .fixing-page .perfect_card p {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .fixing-page .header_logo {
    width: 287px;
  }
  .fixing-page .form_section label {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .fixing-page .accordian_div h5 {
    font-size: 16px !important;
  }
  .fixing-page .accordian_details p {
    font-size: 14px !important;
  }
  .fixing-page .left_content_footer svg {
    width: 130px;
  }
}
@media screen and (min-width: 998px) and (max-width: 1300px) {
  .perfect_card {
    /* height: 355px; */
  }
  .fixing-page .left_content_data h3 {
    font-size: 35px !important;
    line-height: 44.8px !important;
  }
  .fixing-page .team_content {
    font-size: 12px !important;
  }
  .fixing-page .fully_manage_section .bottom_title p {
    font-size: 15px !important ;
  }
  .fixing-page .container,
  .fixing-page .container-lg,
  .fixing-page .container-md,
  .fixing-page .container-sm,
  .fixing-page .container-xl {
    max-width: 1055px !important;
  }
  .fixing-page .header_logo svg {
    width: 129px !important;
  }
  .fixing-page .header-menu {
    font-size: 13px !important;
  }
  .fixing-page button {
    font-size: 13px !important;
    padding: 7px 20px !important;
  }
  .fixing-page .left_content_data span {
    font-size: 19px !important;
    line-height: 24px !important;
  }
  .fixing-page .left_content_data h6 {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .fixing-page h3 {
    font-size: 30px !important;
  }
  .fixing-page .calculator_div h3 {
    line-height: 36px !important;
  }
  .fixing-page .calculator_div p {
    font-size: 18px !important;
  }
  .fixing-page .team_card h6 {
    font-size: 18px !important;
  }
  .fixing-page .team_card p {
    font-size: 13px !important;
  }
  .fixing-page .service_card h6 {
    font-size: 20px !important;
  }
  .fixing-page .service_card p {
    font-size: 13px !important;
    line-height: 18px !important;
    margin-top: 5px;
  }
  .fixing-page .fully_manage_section p {
    line-height: 20px !important;
    font-size: 14px !important;
  }
  .fixing-page .service_name {
    font-size: 15px !important;
  }
  .fixing-page .perfect_card p {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .fixing-page .header_logo {
    width: 287px;
  }
  .fixing-page .form_section label {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .fixing-page .accordian_div h5 {
    font-size: 16px !important;
  }
  .fixing-page .accordian_details p {
    font-size: 14px !important;
  }
  .fixing-page .left_content_footer svg {
    width: 130px;
  }
}

/*-=-=-=-=-=-=-=-=-tablet view-=-=-=-=-=-=-=-=-=-=-=-*/
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .service_card {
    margin: 0 auto;
    padding: 30px 8px !important;
    width: 185px !important;
  }
  .first_service:before {
    position: absolute;
    bottom: -100px;
    width: 130px;
    left: 90px;
    background-size: 100%;
  }
  .second_service:before {
    width: 130px !important;
    left: 93px !important;
    background-size: 100%;
  }
  .main_header_new svg {
    width: 90px;
  }
  .header-menu {
    color: rgb(144, 152, 175);
    font-size: 11px !important;
    margin-right: 14px !important;
  }
  .main_header_new .header_logo {
    width: 250px;
  }
  button {
    font-size: 12px !important;
    padding: 7px 13px !important;
    white-space: nowrap;
  }
  .header_btn_section {
    display: flex;
  }
  .left_content_data h3 {
    font-size: 24px !important;
    margin-bottom: 10px;
    line-height: 30.8px !important;
  }
  .left_content_data span {
    font-size: 16px !important;
    line-height: 16.6px !important;
  }
  .left_content_data h6 {
    font-size: 15px !important;
    line-height: 20px !important;
  }
  .perfect_content_div p {
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .calculator_div h3 {
    font-size: 30px !important;
    line-height: 30.2px !important;
  }
  .team_content {
    font-size: 12px;
    line-height: 17px;
  }
  .team_card p {
    font-size: 13px !important;
    line-height: 17px !important;
  }
  .team_card h6 {
    font-size: 20px !important ;
    line-height: 25px !important;
  }
  .manage_card {
    width: auto !important;
    height: 320px !important;
  }
  .logo_pics svg {
    width: 100px;
  }
  .logo_pics {
    text-align: center;
  }
  /* .perfect_card {
    min-height: 290px !important;
  } */
  .service_section {
    padding: 75px 5px 80px !important;
  }
  .team_section {
    min-height: 630px !important;
  }
  button {
    width: auto !important;
  }
  .form_card button {
    width: 100% !important;
  }
  .perfect_content_div {
    padding: 50px 30px 60px !important;
  }
  .fully_manage_section {
    padding: 60px 100px !important;
  }
  .logistic_partner_section {
    padding: 40px 15px 60px !important;
  }
  .landing_page_content {
    padding-top: 100px !important;
  }
  .accordian_section {
    padding: 40px 0 !important;
  }
  .form_card.ms-5 {
    margin-top: 30px;
    margin-left: 0 !important;
  }
  .form_section {
    padding: 50px 0 110px !important;
  }
  .service_card_colmn {
    padding-top: 0 !important;
  }
  .second_service {
    margin-top: 170px !important;
  }
  /* .buttn_perfect {
    padding-left: 0 !important;
  } */
  .truck_pic {
    bottom: 1px!important;
}
}

/*-===========================big screen phone-=====================*/
@media screen and (min-width: 600px) and (max-width: 768px) {
  .container,
  .container-sm {
    max-width: 680px !important;
  }
  .left_content_data h3 {
    white-space: break-spaces !important;
    font-size: 20px !important;
    line-height: 22.8px !important;
    margin-bottom: 10px !important  ;
  }
  .left_content_data span {
    font-size: 15px !important;
    line-height: 10.6px !important;
  }
  .left_content_data h6 {
    font-size: 13px !important;
    line-height: 20px !important;
  }
  button {
    font-size: 11px !important;
    padding: 7px 9px !important;
    white-space: nowrap;
  }
  .logo_pics {
    text-align: center;
    margin-bottom: 10px;
  }
  .header_logo svg {
    width: 110px !important;
  }
  .calculator_div h3 {
    color: #fff;
    font-size: 26px !important;
  }
  .calculator_div p {
    font-size: 18px !important;
    line-height: 19.6px !important;
  }
  .fully_manage_section p {
    line-height: 19px !important;
    font-size: 14px !important;
  }
  .header_btn_section {
    display: flex;
  }
  .header_logo {
    width: 160px;
  }
  .form_card {
    margin-left: 0 !important;
  }
  .fully_manage_section {
    padding: 60px 100px !important;
    background-size: cover;
  }
  .second_div_img {
    margin-top: 0 !important;
  }
  .position_top_div .first_div_img {
    margin-top: 80px !important;
  }
  .left_content_data {
    margin-top: 40px !important;
  }
  .logistic_partner_section {
    padding: 40px 15px 60px !important;
  }
  .perfect_content_div {
    padding: 70px 30px 50px !important;
  }
  button {
    width: auto !important;
  }
  .form_card button {
    width: 100% !important;
  }
  .form_card {
    margin-top: 50px;
  }
  .header-menu {
    margin-right: 8px !important;
    font-size: 11px !important;
  }
  .service_card {
    margin: 0 auto;
    padding: 30px 8px !important;
    width: 185px !important;
  }
  .service_card p {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .first_service:before {
    position: absolute;
    bottom: -100px;
    width: 130px;
    left: 90px;
    background-size: 100%;
  }
  .service_card h6 {
    font-size: 21px !important;
    line-height: 26.6px !important;
  }
  .second_service:before {
    width: 130px !important;
    left: 93px !important;
    background-size: 100%;
  }
  .team_section {
    min-height: 640px !important;
  }
  .form_section {
    padding: 63px 0 120px !important;
  }
  .form_card.ms-5 {
    margin-left: 0 !important;
  }
  .accordian_section {
    padding: 60px 0 !important;
  }
  .landing_page_content {
    padding-top: 120px !important;
  }
  .form_section {
    padding: 50px 0 110px !important;
  }
  .service_card_colmn {
    padding-top: 0 !important;
  }
  .second_service {
    margin-top: 170px !important;
  }
  .buttn_perfect {
    padding-left: 0 !important;
  }
  .accordian_section {
    padding: 40px 0 !important;
  }
  .truck_pic {
    bottom: 1px!important;
}
}
