@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700&display=swap");
body {
  overflow-x: hidden;
  /* background: linear-gradient(180deg, #F4F5FF -24.83%, rgba(255, 255, 255, 0) 100%); */
}
.footer_link {
  padding-left: 0 !important;
}
.social_login li:last-child {
  margin-right: 0;
}
.main_header_new {
  box-shadow: none;
}
.main_header_new a:last-child {
  margin-right: 0;
}
.basket-icon-step2 {
  width: 33px;
  height: 38px;
}
.second_service:before {
  position: absolute;
  top: -80px;
  background-image: url(../src/assert/images/upLineArray.png);
  height: 80px;
  width: 176px;
  left: 150px;
  content: "";
  background-repeat: no-repeat;
}
.first_service:before {
  position: absolute;
  bottom: -100px;
  background-image: url(../src/assert/images/downLineArray.png);
  height: 100px;
  width: 176px;
  left: 150px;
  content: "";
  background-repeat: no-repeat;
}
.main_header_new .header-menu.active:before {
  content: "";
  border-bottom: 2px solid #6d5bde;
  position: absolute;
  width: 27px;
  height: 2px;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -5px;
  border-radius: -5px;
}
.new_input::placeholder {
  color: #9098af !important;
}
.new_input {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #9098af !important;
  border: 1px solid #d1d2e3;
  border-radius: 4px;
  height: 48px;
}
textarea.new_input {
  height: auto;
}
.envelop {
  display: none;
}
.accordian_div::before {
  content: none !important;
}
.contact_footer li:nth-child(3) .envelop {
  display: inline;
}

.loader-4 {
  position: relative;
  height: 20px;
  width: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-right: 20px;
}
.loader-4 .dbl-spin-1,
.loader-4 .dbl-spin-2 {
  position: absolute;
  box-sizing: border-box;
  display: block;
  margin: auto;
  border: 3px solid transparent;
  border-radius: 50%;
}
.loader-4 .dbl-spin-1 {
  height: 20px;
  width: 20px;
  border-top: 3px solid #9575cd;
  border-left: 3px solid #fff;
  -webkit-animation: loader-4-dblspin 1.8s linear infinite;
  animation: loader-4-dblspin 1.8s linear infinite;
}
.loader-4 .dbl-spin-2 {
  height: 20px;
  width: 20px;
  border-bottom: 3px solid #6a1b9a;
  border-left: 3px solid #6a1b9a;
  -webkit-animation: loader-4-dblspin2 1.2s linear infinite;
  animation: loader-4-dblspin2 1.2s linear infinite;
}
.header_logo {
  width: 366px;
}
@-webkit-keyframes loader-4-dblspin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-720deg);
  }
}

@keyframes loader-4-dblspin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-720deg);
  }
}
@-webkit-keyframes loader-4-dblspin2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-4-dblspin2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.perfect_content_div .MuiGrid-item:nth-child(1){
  padding-left: 0;
}
@media screen and (min-width: 1430px) and (max-width: 1620px) {
  .manage_card {
    min-height: 330px !important;
    width: 255px !important;
  }
  .truck_pic {
    width: 300px !important;
    height: 107px !important;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1430px) {
  .manage_card {
    min-height: 290px !important;
    width: 230px !important;
  }
  .truck_pic {
    width: 240px !important;
    height: 86px !important;
  }
  .css-1s7phgi .fully_manage_section .bottom_title p {
    font-size: 15px !important ;
  }
}
@media screen and (min-width: 998px) and (max-width: 1300px) {
  .left_content_data h3 {
    font-size: 40px !important;
  }
  .team_content {
    font-size: 14px !important;
  }
  .css-1s7phgi .fully_manage_section .bottom_title p {
    font-size: 15px !important ;
  }
}
/*-=-=-=-=-=-=-mobile responsive-=-=-=--=-*/
@media screen and (min-width: 320px) and (max-width: 600px) {
  .social_footer div {
    text-align: left !important;
  }
  .left_content_data{
    text-align: center;
  }
  .social_footer h5 {
    margin: 0 !important;
  }
  .left_content_data {
    margin-top: 15px !important;
  }
  .left_content_data span {
    line-height: 24px !important;
    font-size: 22px !important;
  }
  .header_logo svg {
    width: 108px !important;
  }
  .border_btn_head {
    border: 2px solid #6d5bde;
    font-size: 13px !important;
    padding: 6px 7px !important;
  }
  br {
    display: none !important;
  }
  .big-btn-header {
    display: none !important;
    /* top:0;
    position: absolute!important;
    right: 15px;
    left: 15px;
    width: 90%!important; */
  }
  .logo_pics {
    text-align: center;
    margin-bottom: 15px;
  }
  .border_btn_head {
    margin-right: 0 !important;
  }
  .header_logo svg {
    width: 130px;
  }
  /* .main_header_new button {
    padding: 10px !important;
  } */
  .position_top_div .first_div_img {
    margin-top: 118px !important;
  }
  .first_service:before {
    content: none;
  }
  .second_service:before {
    content: none;
  }
  .second_service {
    margin-top: 0 !important;
    position: relative;
  }
  .first_service {
    margin-top: 20px;
  }

  .fully_manage_section {
    padding: 50px 15px !important;
    background-size: cover !important;
  }
  .team_section {
    padding: 40px 15px !important;

    background-size: cover !important;
  }
  .manage_card {
    min-height: 300px !important;
    width: auto !important;
    margin-top: 15px!important;
  }
  .button_section_mb {
    display: flex;
    justify-content: center;
  }
  .form_section .form_card {
    margin-top: 30px;
    padding: 15px !important;
    margin-left: 0 !important;
  }
  .accordian_section {
    padding: 50px 15px 30px !important;
  }
  .form_section {
    padding: 30px 15px 100px !important;
  }
  .truck_pic {
    bottom: 1px!important;
}
  ul.side_menu {
    padding: 0 0 0 0px;
  }
  .side_menu a {
    text-align: left;
    color: #9098af;
    display: block;
    text-decoration: none;
    padding: 5px 10px;
  }
  .side_menu a:hover {
    color: rgb(109, 91, 222);
  }
  .drawer-btn {
    margin-top: 7px !important;
    padding: 5px 10px !important;
    font-size: 13px !important;
  }
  .drawer_menu_div {
    padding: 10px 15px;
  }
  .second_div_img {
    margin-top: 15px;
  }

  .bottom_footer {
    margin-top: 10px;
  }
  .landing_page_content {
    padding-top: 20px !important;
    margin-top: 60px !important;
  }
  .first_section br {
    display: none;
  }
  .contact_icon {
    margin: 0 auto;
  }
  .form_icon_div {
    text-align: center;
  }
  .left_section_form br {
    display: none;
  }
  .left_content_data h3 {
    white-space: break-spaces !important;
    font-size: 33px !important;
    line-height: 37px !important;
    margin-bottom: 10px!important;
  }
  .calculator_div {
    padding: 60px 10px !important;
    background-size: cover !important;
  }
  .calculator_div h3 {
    font-size: 33px !important;
    line-height: 36.2px!important;
  }
  .border_btn_head {
    margin-right: 0 !important;
  }
  html,
  body,
  #root {
    overflow-x: hidden;
  }
  .header_logo {
    width: auto;
  }
  .fully_manage_section .MuiGrid-grid-xs-12 {
    padding-left: 0 !important;
  }
  .service_card {
    margin: 0 auto;
    width: 270px;
  }
  .perfect_card {
    /* margin: 40px 0px 10px !important; */
    padding: 30px 10px 0 !important;
  }
  .perfect_content_div {
    padding: 50px 15px 50px !important;
  }
  .team_card h6 {
    line-height: 22px;
    margin-bottom: 7px;
    font-size: 23px!important;
  }
  .team_card p {
    line-height: 20px !important;
  }
  .position_top_div {
    justify-content: center !important;
  }
  .logistic_partner_section {
    padding: 30px 15px 30px !important;
  }
  .logo_pics svg {
    width: 200px;
    height: 43px;
  }
  .buttn_perfect {
    padding-left: 0 !important;
  }
  .team_content {
    color: #fff;
    font-size: 17px !important;
  }
  .service_section {
    padding: 50px 5px 80px !important;
  }
  .service_section h3 {
    font-size: 33px !important;
    margin-bottom: 37px !important;
  }
  .team_card {
    display: block !important;
    text-align: center !important;
  }
  .icon_box {
    display: block;
    margin: 0 auto 15px;
  }
  .team_card .ms-3 {
    margin-left: 0 !important;
  }
  .fully_manage_section p {
    font-size: 17px !important;
    line-height: 20px!important;
  }
  /* .perfect_card {
    min-height: 290px !important;
  } */
  .footer_list {
    padding: 35px 0px 25px !important;
  }
  .accordian_section h3 {
    font-size: 33px !important;
    letter-spacing: 0 !important;
  }
  .left_content_data h6 {
    font-size: 18px !important;
    line-height: 24px !important;
    margin: 14px 0 2px!important;
  }
  .position_top_div {
    margin-top: 45px!important;
}
  .accordian_section p.mb-5 {
    margin-bottom: 30px !important;
  }
  .fully_manage_section h3 {
    font-size: 33px!important;
    line-height: 37.2px!important;}
    .perfect_content_div .MuiGrid-item {
      padding-left: 0px!important;
  }
  .perfect_content_div button{
    margin-top: 0px;
  }
}
