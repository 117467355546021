body {
    background-color: #403836;
}

.login_form {
    width: 450px;
    margin: 5% auto;
    border-radius: 20px;
    padding: 25px;
    border: 1px solid #2B3A90;
}

.logo_div {
    text-align: center;
}

.login_form .logo_div {
    margin-bottom: 40px;
}

.form_input {
    color: #403836;
    border-radius: 0px !important;
}

.logo_div img {
    height: 55px;
    width: 180px;
}

.form_input::placeholder {
    color: #403836 !important;
}

.main_button {
    background-color: #2B3A90;
    box-shadow: none;
    color: #fff;
    font-weight: 600;
    border-radius: 73px;
    padding: 10.76px 54px;
}

.main_button:hover {
    background-color: #2B3A90 !important;
    color: #fff !important;
}

.forgot_btn {
    font-size: 12px;
    text-decoration: none;
}