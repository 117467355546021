body {
    background-color: #fff !important;
}

.main_admin_body {
    display: flex;
    justify-content: space-between;
}

.main_admin_body .sidebar_admin {
    padding: 15px;
    width: 22%;
    height: 100vh;
    background-color: #403836;
    position: fixed;
    box-shadow: 1px 1px 10px #999;
}

.main_admin_body .admin_rightside_section {
    width: 100%;
    padding-left: 22%;
    /* background-color: #D9CCC5; */
    height: 100vh;
}

.main_admin_body .header_section {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    padding: 10px 15px;
    align-items: center;
    width: 78%;
    margin-left: 0;
    z-index: 9;
    box-shadow: 6px 1px 10px #999;
}

.main_admin_body .header_section ul {
    margin-bottom: 0;
}

.main_admin_body .header_section li {
    list-style: none;
}

.main_admin_body .header_section button {
    background-color: transparent !important;
    border: none !important;
    color: #000;
}

.admin_content_section {
    margin-top: 55px;
}

/*-==-=-=-=-=-=-=-=-=-=-=-=-side bar css-=-=-=-=-=-=-==-*/
.main_admin_body .sidebar_admin {
    background-color: #fff;
}

.main_admin_body .sidebar_logo {
    text-align: center;
}

.main_admin_body .sidebar_logo img {
    width: 180px;
    height: 55px;
}

.main_admin_body .side_menu_list ul.main_list_sidebar {
    margin-top: 20px;
    padding: 0;
    height: 99vh;
    overflow-y: auto;
    padding-bottom: 80px;
    padding-right: 10px;
}

ul.main_list_sidebar::-webkit-scrollbar {
    width: 6px;
}

/* Track */
ul.main_list_sidebar::-webkit-scrollbar-track {
    background: #403836;
    border-radius: 8px;
}

/* Handle */
ul.main_list_sidebar::-webkit-scrollbar-thumb {
    background: #D9CCC5;
}

/* Handle on hover */
ul.main_list_sidebar::-webkit-scrollbar-thumb:hover {
    background: #D9CCC5;

}

.side_menu_list li {
    list-style: none;
    padding: 10px;
    margin: 10px 0;
}

.side_menu_list li svg {
    color: #D9CCC5;
}

.job_dropdown_sidemenu li {

    list-style: none;
    padding: 5px 10px;
    margin: 5px 0;

}

.side_menu_list li.active {
    border-radius: 5px;
    background-color: #2B3A90;
    /* padding: 10px; */
}

.side_menu_list li.active a {
    color: #fff
}

.side_menu_list li a {
    color: #000;
    text-decoration: none;
}

.side_menu_list a {
    display: block;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
    background: #403836;
    border-radius: 8px;
}

/* Handle */
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
    background: #D9CCC5;
}

/* Handle on hover */
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
    background: #D9CCC5;

}

label {
    font-weight: bold;
    margin-bottom: 3px;
}

.logout_btn {
    text-decoration: none;
}