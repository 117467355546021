body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #faffff;
}

code {
  font-family: 'Roboto';
}

.text-right{text-align: right !important;}
img{max-width: 100%;}
.text-left{text-align: left !important;}

/* Hide number input arrows */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
   appearance: textfield;
  -moz-appearance: textfield;
}