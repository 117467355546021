.card_body {
    background-color: #fff;
    /* border-radius: 10px; */
    padding: 25px;
    box-shadow: 0 9px 50px -3px rgb(0 0 0 / 20%)
}

.page_title h3 {
    margin: 0;
}

.main_button {
    background-color: #2B3A90 !important;
    box-shadow: none;
    color: #fff;
    font-weight: 600;
    border-radius: 73px !important;
    padding: 10.76px 54px;
}

.main_button:hover {
    background-color: #2B3A90 !important;
    color: #fff !important;
}

.small_button,
.small_button:hover {
    background-color: #403836 !important;
    border: 1px dashed #403836 !important;
    color: #fff !important;
    padding: 3px 5px;
    border-radius: 0px;
    font-size: 12px;
    border-radius: 5px;
}


textarea.form_input {
    padding: .375rem .75rem;
    border: none;
}

/*-=-=-=-=-=-=-=-=-add job form-=-=-=-=-=-=-*/

.form_input {
    color: #403836;
    border-radius: 0px !important;
    width: 100%;
    margin-bottom: 20px;
}

.form_input::placeholder {
    color: #403836 !important;
}

.edit_icon_btn svg {
    color: green;
    cursor: pointer;
    padding: 5px;
    font-size: 32px;
    margin-left: 5px;
}

.delete_icon_btn svg {
    margin-left: 5px;
    color: red;
    padding: 5px;
    cursor: pointer;
    font-size: 32px;
}

.view_icon_btn svg {
    color: #403836;
    padding: 5px;
    cursor: pointer;
    font-size: 32px;
}